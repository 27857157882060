// eslint-disable-next-line import/order
import { navigate } from "gatsby";

import Heading from "@/components/elements/Heading";
import Input from "@/components/elements/Input";
import { ReactComponent as SearchIco } from "@/assets/svgs/search.svg";

import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

interface SearchHeroProps {
  searchQuery: string | null;
  searchValue: string;
  setSearchQuery: (searchValue: string) => void;
  searchResultsLength: number;
  setPage: (page: number) => void;
}

export function SearchHero({
  setSearchQuery,
  searchQuery,
  searchValue,
  searchResultsLength,
  setPage,
}: SearchHeroProps) {
  const { t, language_code } = useLanguageContext();

  const handleSearch = () =>
    navigate(
      `/${language_code}/search?query=${encodeURIComponent(searchValue)}`
    );

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setSearchQuery("");
    handleSearch();
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <span className={styles.subtitle}>{t`search.searchResults`}</span>
        <Heading>
          {t`search.resultsFor`}: &quot;{searchQuery}&quot;
        </Heading>
        <form onSubmit={handleSubmit}>
          <Input
            withIcon={
              <SearchIco className={styles.searchIcon} onClick={handleSearch} />
            }
            value={searchValue}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t`search.searchAgain`}
            type="text"
            name="search"
          />
        </form>
        <p>
          {t`search.weHaveFound`} {searchResultsLength}{" "}
          {searchResultsLength === 1 ? t`search.result` : t`search.results`}
        </p>
      </div>
    </section>
  );
}
