import { useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";

// import { useInView } from "react-intersection-observer";
import MainLayout from "@/components/layout/Main";

import { useAlgoliaIndex } from "@/base/hooks/useAlgoliaIndex";
import useSessionStorage from "@/base/hooks/useSessionStorage";
// import LazyIntersectionObserver from "@/components/elements/LazyIntersectionObserver";
import SearchHero from "@/components/sections/SearchHero";
import SearchResults from "@/components/sections/SearchResults";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import { LanguageContextProvider } from "@/base/context/LanguageContext";
import { getLayoutData } from "@/base/helpers/getLayoutData";
import { useLocation } from "@reach/router";

const SearchPage = () => {
  const location = useLocation();
  const locale = getLocaleFromPathname(location.pathname);
  const urlParams = new URLSearchParams(location.search);

  const [storedValue, setValue] = useSessionStorage<string>(
    "alpro_searchPage",
    "0"
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<
    [Array<HitPageType>, number]
  >([[], 0]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { index, isIndexReady } = useAlgoliaIndex();

  useEffect(() => {
    if (page !== parseInt(storedValue)) {
      setPage(parseInt(storedValue));
    }
  }, []);

  useEffect(() => {
    const searchQueryParam = urlParams.get("query");

    if (index && isIndexReady) {
      index
        .search(searchQueryParam || "", {
          page,
          hitsPerPage: 5,
          facetFilters: [`locale:${locale}`],
        })
        .then((res) => {
          if (res.page !== page) {
            setPage(res.page);
          }

          setTotalPages(res.nbPages);
          setSearchResults([res.hits as unknown as HitPageType[], res.nbHits]);
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  }, [location.search, page]);

  const handleSetPage = (page: number) => {
    setValue(page.toString());
    setPage(page);
  };

  const { headers, footers, site } = useStaticQuery(graphql`
    query SearchPageQuery {
      site {
        siteMetadata {
          ogImage
        }
      }
      headers: allStoryblokEntry(
        filter: { field_component: { eq: "template_header" } }
      ) {
        nodes {
          full_slug
          content
        }
      }
      footers: allStoryblokEntry(
        filter: { field_component: { eq: "template_footer" } }
      ) {
        nodes {
          full_slug
          content
        }
      }
    }
  `);

  const { header, footer } = getLayoutData(locale, headers, footers);

  const seo = {
    description: "Search results",
    title: "Search results",
  };

  return (
    <LanguageContextProvider language_code={locale}>
      <MainLayout header={header} footer={footer} seo={seo} type="website">
        <SearchHero
          setSearchQuery={setSearchQuery}
          searchQuery={urlParams.get("query")}
          searchValue={searchQuery}
          searchResultsLength={searchResults[1]}
          setPage={handleSetPage}
        />
        <SearchResults
          searchResults={searchResults[0]}
          setPage={handleSetPage}
          currentPage={page}
          totalPages={totalPages}
          defaultOgImage={site.siteMetadata.ogImage as string}
        />
      </MainLayout>
    </LanguageContextProvider>
  );
};

export default SearchPage;
